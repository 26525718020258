import ReactInputMask from "react-input-mask";
import {
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { STORAGE_URL } from "variables";
import Card from "./Card";
import "./style.css";
import { useEffect } from "react";

const PaymentInfo = ({
  course,
  cpf,
  setCpf,
  number,
  setNumber,
  brand,
  setBrand,
  due_date,
  setDueDate,
  cvv,
  setCvv,
  cvvLength,
  setCvvLength,

  user_name,
  setUserName,
  gender,
  setGender,
  birth_date,
  setBirthDate,
  phone,
  setPhone,

  font_color,
  accent_color,
}) => {
  const [card_name, setCardName] = useState("");

  function handleCardChange(text = "") {
    text = text.replace(/ /g, "");
    let card = new Card();
    let types = Object.values(card.type);
    let brand = "";
    let index = null;
    let masked_number = "";
    let card_length = 0;
    let cvv = "";
    for (let i = 0; i < types.length; i++) {
      if (text.match(types[i].detector)) {
        brand = types[i].name;
        card_length = types[i].cardLength;
        cvv = types[i].cvcLength;
        index = i;
        break;
      }
    }
    if (index != null) {
      let index_mask = 0;

      for (let i = 0; i < text.length && i < types[index].cardLength; i++) {
        if (!isNaN(text[i])) {
          if (types[index].maskCC[index_mask] === " ") {
            masked_number += " ";
            index_mask++;
          }
          if (types[index].maskCC[index_mask] === "0") {
            masked_number += text[i];
          }
          index_mask++;
        }
      }
    } else {
      masked_number = text;
    }
    console.log("teste cartao", brand, masked_number, index, card_length, cvv);
    //limpar a formatação ao enviar pro back
    setNumber(masked_number);
    setBrand(brand);
    setCvvLength(cvv);
  }

  function handleCvvChange(val) {
    let value = "";
    let length = Array.from(val);

    length.forEach((item, index) => {
      if (index < cvvLength) value = value + item;
    });
    setCvv(value);
  }

  return (
    <div
      style={{
        paddingTop: "0",
        display: "flex",
        gap: "2vw",
        alignItems: "center",
      }}
    >
      <div className="row">
        <div className="row pe-0">
          <Typography
            variant="h6"
            style={{
              color: font_color,
            }}
          >
            Dados do cartão
          </Typography>
          <div
            className="row"
            style={{
              padding: "0",
              marginLeft: "0rem",
            }}
          >
            <div className="col-sm-12 mt-3 pe-0">
              <TextField
                fullWidth
                label="Nome impresso no cartão"
                size="small"
                value={card_name}
                onChange={(e) => setCardName(e.target.value)}
                sx={{
                  backgroundColor: "white",
                }}
              />
            </div>
            <div className="col-sm-6 mt-3 flex">
              <TextField
                label="Número do Cartão"
                size="small"
                value={number}
                sx={{
                  backgroundColor: "white",
                  width: "100%",
                }}
                onChange={(e) => handleCardChange(e.target.value)}
              />
              <div className="brand">
                <img
                  src={`${STORAGE_URL}brands/${brand ? brand : "nocard"}.png`}
                  alt="brand"
                ></img>
              </div>
              {/* <Button variant='contained'>Teste</Button> */}
            </div>
            <div className="col-sm-3 mt-3">
              <ReactInputMask
                mask={"99/99"}
                value={due_date}
                sx={{
                  backgroundColor: "white",
                }}
                onChange={(e) => setDueDate(e.target.value)}
                disabled={false}
                maskChar=""
              >
                {() => (
                  <TextField
                    fullWidth
                    label="Vencimento"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                    }}
                  />
                )}
              </ReactInputMask>
            </div>
            <div className="col-sm-3 mt-3 pe-0">
              <TextField
                fullWidth
                label="CVV"
                size="small"
                value={cvv}
                sx={{
                  backgroundColor: "white",
                }}
                onChange={(e) => handleCvvChange(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Typography
          variant="h6"
          style={{
            marginTop: "1.2rem",
            color: font_color,
          }}
        >
          Dados do pagador
        </Typography>
        <div className="col-sm-8 mt-3">
          <TextField
            fullWidth
            label="Nome completo"
            size="small"
            value={user_name}
            sx={{
              backgroundColor: "white",
            }}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="col-sm-4 mt-3">
          <Select
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            size="small"
            label="Sexo"
            sx={{
              backgroundColor: "white",
            }}
            onChange={(e) => {
              setGender(e.target.value);
            }}
          >
            <MenuItem value="Feminino">Feminino</MenuItem>
            <MenuItem value="Masculino">Masculino</MenuItem>
            <MenuItem value="Outro">Outro</MenuItem>
          </Select>
        </div>
        <div className="col-sm-4 mt-3">
          <ReactInputMask
            mask={"99/99/9999"}
            value={birth_date}
            sx={{
              backgroundColor: "white",
            }}
            onChange={(e) => setBirthDate(e.target.value)}
            disabled={false}
            maskChar=""
          >
            {() => (
              <TextField
                fullWidth
                label="Data de nascimento"
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
              />
            )}
          </ReactInputMask>
        </div>
        <div className="col-sm-4 mt-3">
          <ReactInputMask
            mask={"999.999.999-99"}
            value={cpf}
            sx={{
              backgroundColor: "white",
            }}
            onChange={(e) => setCpf(e.target.value)}
            disabled={false}
            maskChar=""
          >
            {() => (
              <TextField
                fullWidth
                label="CPF"
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
              />
            )}
          </ReactInputMask>
        </div>
        <div className="col-sm-4 mt-3">
          <ReactInputMask
            mask="+55 (99) 99999-9999"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={false}
            maskChar=""
          >
            {() => (
              <TextField
                fullWidth
                label="Telefone"
                size="small"
                sx={{
                  backgroundColor: "white",
                }}
              />
            )}
          </ReactInputMask>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
