import { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Get, Post } from "utils/request";
import { API_URL } from "variables";

const useHomeCourse = () => {

    const user = useSelector(store => store.AppReducer.user);
    const { course_id } = useParams();
    const history = useHistory();

    const { isLoading, error, data } = useQuery(["course", course_id], () => Get(`courses-main?id=${course_id}`), {
        refetchOnWindowFocus: false
    });
    const {
        course = {}
    } = data || {};

    const { mutate: generateCertificate } = useMutation(async () => {
        return await Post(`certificate/generate?user_id=${user?.id}&course_id=${course_id}`);
    });

    useEffect(() => (data?.status === true && data?.error == "NOT_IN") && history.push(`/courses/adquire/${course_id}`), [data]);

    useEffect(() => course?.progress >= 0 && generateCertificate(), [course]);

    return {
        course,
        isLoading
    };
}

export default useHomeCourse;