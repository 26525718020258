import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { Header } from "modules/Header";
import { useMainPages } from "modules/Header/data";
import { loggedPages } from "modules/Header/data";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserInfo from "./sections/UserInfo";
import { useParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CheckoutHeader from "./CheckoutHeader";
import PaymentIcon from "@mui/icons-material/Payment";
import AddressInfo from "./sections/AddressInfo";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ConfirmOrder from "./sections/ConfirmOrder";
import { Get, Post } from "utils/request";
import CheckIcon from "@mui/icons-material/Check";
import { STORAGE_URL } from "variables";
import { useHistory } from "react-router";
import zIndex from "@mui/material/styles/zIndex";
import { Show } from "actions/SnackbarActions";
import { useDispatch } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { removeHTML } from "utils/filters";
import Methods from "./sections/Payment/Methods";
import MethodsMapping from "./sections/Payment/MethodsMapping";
import CustomerInfo from "./CustomerInfo";
import { Home, Phone } from "@mui/icons-material";
import UserCards from "pages/Profile/Card";
import MultiCards from "./sections/Payment/MultiCards";
import moment from "moment-timezone";
import Counter from "./sections/Payment/Counter";
import { TextField, MenuItem } from "@mui/material";
import { maskPrice } from "Auxiliar/Masks";
const CheckoutPage = ({ preview_course, preview_mode }) => {
  const pages = useMainPages();
  const dispatch = useDispatch();
  const history = useHistory();
  const { course_id } = useParams();
  let user_id = null;

  const [is_loading, setIsLoading] = useState(true);
  const [order_id, setOrderId] = useState(null);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [expired, setExpired] = useState(false);

  const [paymentPix, setPaymentPix] = useState(false);
  const [is_student, setIsStudent] = useState(false);

  const [pixData, setPixData] = useState({});

  const [popup, SetPopup] = useState("");
  const [is_logged, setIsLogged] = useState(undefined);
  const [course, SetCourse] = useState({});
  const [user, setUser] = useState({});
  const [selectedOpcells, setSelectedOpcells] = useState([]);
  const [opcells, setOpcells] = useState([]);
  const [total_price, setTotalPrice] = useState(0);
  const [discount_price, setDiscountPrice] = useState(null);
  const [used_coupon, setUsedCoupon] = useState(null);
  const [discount_type, setDiscountType] = useState(null);
  const [loading_order, setLoadingOrder] = useState(false);
  // payment states
  const [method, setMethod] = useState(MethodsMapping.pix);
  const [installments, setInstallment] = useState(1);

  const [cpf, setCpf] = useState("");
  const [cardId, setCardId] = useState("");
  const [multi_cards, setMultiCards] = useState([]);

  // user info
  const [user_name, setUserName] = useState();
  const [gender, setGender] = useState("");
  const [birth_date, setBirthDate] = useState("");
  const [phone, setPhone] = useState();
  const currentUser = useSelector((store) => store.AppReducer.user);

  const timeout_order = useRef();

  const getOrder = async (id, force = false) => {
    let response = await Get(`orders/${id}`);
    console.log(response);
    if (force == true && response.order.charge.status != "paid") {
      setExpired(true);
      return;
    }

    if (response.order.charge.status == "paid") {
      setPaymentPix(false);
      setPaymentSuccess(true);
      return;
    }

    timeout_order.current = setTimeout(() => {
      getOrder(id);
    }, 10000);
  };

  const handleTimeoutPayment = (createdAt) => {
    createdAt = new Date(createdAt); // Converte a string para um objeto Date
    let now = new Date(); // Obtém a data e hora atual
    // console.log(createdAt)
    // console.log(now)

    let milisecondsDifference = createdAt - now; // Calcula a diferença em milissegundos
    // console.log(milisecondsDifference)
    let seconds_diff = parseInt(milisecondsDifference / 1000); // Converte para minutos
    let timeout = now > createdAt; // Verifica se mais de 5 minutos se passaram
    if (timeout == true) {
      clearTimeout(timeout_order.current);
      getOrder(order_id, true);
    }
    // let seconds = Math.max(0, Math.floor((15 - minutesDifference) * 60)); // Calcula os segundos restantes
    // console.log({
    //   timeout: timeout,
    //   seconds: seconds_diff
    // })
    return {
      timeout: timeout,
      seconds: seconds_diff,
    };
  };

  const getCourse = async () => {
    const response = await Get(
      `courses-main/one/${
        preview_mode && preview_course?.id ? preview_course?.id : course_id
      }?filter_content=1`
    );

    if (response?.status) {
      if (preview_mode) {
        if(response?.is_student){
          preview_course.price = preview_course.customer_discount_price
        }
        SetCourse(preview_course);
      } else {
        if(response?.is_student){
          response.course.price = response?.course.customer_discount_price
        }
        SetCourse(response?.course);
      }
      setIsStudent(response?.is_student)

      let opcellsArray = [];
      if (response?.course?.opcells) {
        response?.course?.opcells.forEach((opcell) => {
          opcellsArray.push(JSON.parse(opcell));
        });
      }
      setOpcells(opcellsArray);
      getUser();
    }
  };

  const handleMultiPaymentCards = (card_id) => {
    if (multi_cards.includes(card_id)) {
      setMultiCards(multi_cards.filter((card) => card !== card_id));
    } else if (multi_cards.length < 2) {
      setMultiCards([...multi_cards, card_id]);
    } else {
      SetPopup("Você pode selecionar apenas dois cartões no multipagamento");
    }
  };

  const buyCourse = async () => {
    // address verification
    console.log(user);
    if (!user?.adress && !address_id) {
      return SetPopup("Você precisa cadastrar um endereço para continuar");
    }

    if (form_edited) {
      return SetPopup(
        "Você precisa salvar as alterações feitas no endereço para continuar"
      );
    }

    // user verification
    if (user_name === "") {
      return SetPopup("Você precisa preencher o nome para continuar");
    } else if (birth_date === "") {
      return SetPopup(
        "Você precisa preencher a data de nascimento para continuar"
      );
    } else if (cpf === "") {
      return SetPopup("Você precisa preencher o CPF para continuar");
    } else if (gender === "") {
      return SetPopup("Você precisa preencher o sexo para continuar");
    } else if (phone === "") {
      return SetPopup("Você precisa preencher o telefone para continuar");
    }
    console.log(phone);
    let payment_body = { ...mountPaymentBody() };
    console.log(payment_body);
    if (Object.values(payment_body).length == 0) {
      return;
    }
    setLoadingOrder(true);
    let variation = course.variations.filter((item)=>item.is_student==is_student && item.installment==installments)[0]?.price
    const response = await Post(
      "orders/create",
      // JSON.stringify(jsonParsedFOrm)
      JSON.stringify({
        total_price: parseFloat(total_price.toString().replace(".", "")),
        used_coupon: used_coupon,
        customer: {
          name: user_name,
          birthdate: birth_date.replaceAll("/", "-"),
          document: cpf.replaceAll(".", "").replace("-", ""),
          gender: gender === "Masculino" ? "Masculino" : "Feminino",
          phone: phone.replace(/\D/g, "").replace(/^\+55/, ""),
        },

        ...payment_body,

        items: [
          {
            id: course_id,
            name: removeHTML(course?.name),
            description: course?.description,
            amount:
              installments == 1
                ? parseFloat(course?.price.toString().replace(".", ""))
                : (!variation? parseFloat(
                    parseFloat(
                      course?.installments_price.toString().replace(".", "")
                    ) * parseFloat(course?.installments_quantity)
                  ): parseFloat(variation*installments).toFixed(2).replaceAll('.','')),
            quantity: 1,
            discount: null,
          },

          ...selectedOpcells.map((opcell) => {
            return {
              id: opcell?.id,
              name: removeHTML(course?.name),
              description: opcell?.description,
              amount:
                installments == 1
                  ? opcell?.price.replace("R$ ", "").replace(",", "")
                  : parseFloat(
                      parseFloat(
                        opcell?.installments_price.toString().replace(".", "")
                      ) * parseFloat(opcell?.installments_quantity)
                    ), // format price
              quantity: 1,
              discount: null,
            };
          }),
        ],

        shipping: {
          address: {
            id: address_id,
            zip_code: adress["zipCode"].value.replaceAll("-", ""),
            state: adress["state"].value,
            city: adress["city"].value,
            number: adress["number"].value.toString(),
            street: adress["street"].value,
            neighborhood: adress["neighborhood"].value,
            complement: adress["complement"].value,
            country: "BR",
          },
          amount: 0,
        },
      }),

      { "Content-Type": "application/json" }
    );

    // const response = await Post(
    //   "orders/create", form
    // );

    if (response.status) {
      if (response.order.charges[0].payment_method == "pix") {
        setPixData(response.order.charges[0].last_transaction);
        setPaymentPix(true);
        setOrderId(response.order.id);
        timeout_order.current = setTimeout(() => {
          getOrder(response.order.id);
        }, 10000);
      } else {
        setPaymentSuccess(true);
      }
    } else {
      SetPopup(response.message);
    }
    setLoadingOrder(false);
  };

  const mountPaymentBody = () => {
    switch (method) {
      case 0:
        return {
          payment: {
            payment_method: "pix",
          },
        };
      case 1:
        if (cardId === "") {
          SetPopup("Você precisa selecionar um cartão para continuar");
          return false;
        }

        return {
          payment: {
            card_id: cardId,
            payment_method: ["pix", "credit_card", "multi_payment"][method],
            installments: installments,
          },
        };
      case 2:
        console.log(multi_cards);
        if (multi_cards.length <= 1) {
          console.log("ENTROU");
          SetPopup(
            "Você precisa selecionar pelo menos dois cartões para continuar"
          );
          return false;
        } else if (multi_cards.length > 2) {
          SetPopup("Você só pode selecionar dois cartões para continuar");
          return false;
        }

        return {
          multi_payment: multi_cards.map((card) => {
            return {
              card_id: card,
              payment_method: ["pix", "credit_card", "multi_payment"][1],
              installments: installments,
              amount: parseFloat(
                parseFloat(total_price / multi_cards.length)
                  .toFixed(2)
                  .replace(/\./g, "")
              ),
            };
          }),
        };
      default:
        break;
    }
  };

  const getUser = async () => {
    if(currentUser.role=='Admin'){
      return;
    }
    if (user_id === null) {
      setIsLogged(false);
      return setIsLoading(false);
    }

    const response = await Get(`user?id=${user_id}`);
    if (response?.status) {
      setUser(response?.user);
      setIsLoading(false);
    }
  };

  const validateCoupon = async () => {
    const form = new FormData();
    form.append("course_id", course_id);
    form.append("used_coupon", used_coupon);
    form.append("total_price", total_price);

    const response = await Post(`coupon/validate-coupon`, form);

    dispatch(
      Show({
        show: true,
        severity: response?.status ? "success" : "error",
        message: response?.message,
      })
    );

    if (response?.status) {
      setDiscountPrice(response.calculated_price);
      setDiscountType(response.discount);
    }
  };

  // adress
  const [form_edited, SetFormEdited] = useState(false);
  const [has_adress, SetHasAdress] = useState(false);

  const [address_id, SetAddressId] = useState(null);
  const [adress, SetAdress] = useState({
    street: {
      value: "",
      error: false,
      helperText: "",
    },
    number: {
      value: "",
      error: false,
      helperText: "",
    },
    complement: {
      value: "",
      error: false,
      helperText: "",
    },
    neighborhood: {
      value: "",
      error: false,
      helperText: "",
    },
    city: {
      value: "",
      error: false,
      helperText: "",
    },
    zipCode: {
      value: "",
      error: false,
      helperText: "",
      handleChange: (cep) => searchAdressByCEP(cep),
    },
    state: {
      value: "",
      error: false,
      helperText: "",
    },
  });

  // submit adress
  const submitAdress = async () => {
    // save user info
    let form = new FormData();
    form.append("id", user.id);
    form.append("street", adress["street"].value);
    form.append("number", adress["number"].value);
    form.append("complement", adress["complement"].value);
    form.append("neighborhood", adress["neighborhood"].value);
    form.append("city", adress["city"].value);
    form.append("state", adress["state"].value);
    form.append("zipCode", adress["zipCode"].value);

    console.log(...form);

    let response = await Post(`auth/edit_profile`, form);
    if (response.status === 200 || response.status === true) {
      SetAddressId(response?.user?.adress_id);
      dispatch(
        Show({
          show: true,
          severity: response?.severity,
          message: response?.message,
        })
      );
      SetFormEdited(false);
      SetHasAdress(true);
    }
  };

  const searchAdressByCEP = async (cep) => {
    const api_fetch = await fetch(
      `https://viacep.com.br/ws/${cep.replace("-", "")}/json/`,
      { mode: "cors" }
    );
    const data = await api_fetch.json();

    if (data.hasOwnProperty("erro")) {
    } else {
      const { logradouro, uf, bairro, localidade } = data;
      SetAdress({
        ...adress,
        ["street"]: { ...adress["street"], value: logradouro },
        ["state"]: { ...adress["state"], value: uf },
        ["neighborhood"]: { ...adress["neighborhood"], value: bairro },
        ["city"]: { ...adress["city"], value: localidade },
        ["zipCode"]: { ...adress["zipCode"], value: cep },
      });
    }
  };

  useEffect(() => {
    if (used_coupon) {
      validateCoupon();
    }
  }, [total_price]);

  useEffect(() => {
    if (is_loading) return;

    if (user?.role === undefined || user?.role === null) {
      setIsLogged(false);
    } else {
      setIsLogged(true);
    }
    console.log(user);
    console.log(user.name);

    setUserName(user?.name ?? "");
    setGender(user?.gender ?? "");
    setBirthDate(user?.birth_date ?? "");
    setCpf(user?.document ?? "");
    setPhone(user?.phone ?? "");
  }, [user]);

  useEffect(() => {
    try {
      user_id = JSON.parse(localStorage.getItem("user")).id;
    } catch {
      user_id = null;
    }
    getCourse();

    return ()=>{
      clearTimeout(timeout_order.current);
    }
  }, []);

  useEffect(() => {
    try {
      user_id = JSON.parse(localStorage.getItem("user")).id;
    } catch {
      user_id = null;
    }
    getCourse();
  }, [preview_course]);

  const SetPopupRender = (message) => {
    return (
      <SweetAlert
        warning
        show={popup !== ""}
        title={popup}
        confirmBtnText="Ok"
        onConfirm={() => SetPopup("")}
      />
    );
  };

  if (is_loading)
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "90vh",
        }}
      >
        <CircularProgress />
        <Typography variant="h5" style={{ marginLeft: "1rem" }}>
          Carregando...
        </Typography>
      </div>
    );

  if (!is_logged && is_logged !== undefined) {
    history.push("/courses/adquire/" + course?.id);

    setTimeout(() => {
      dispatch(
        Show({
          show: true,
          severity: "success",
          message:
            "Você ainda não tem uma conta! Se inscreva no curso para continuar.",
        })
      );
    }, 1000);
  }

  const installmentsOptions = () => {
    console.log(selectedOpcells);
    let price = parseFloat(course.price);
    let installments_price =
      parseFloat(course.installments_quantity) *
      parseFloat(course.installments_price);
    price += parseFloat(
      selectedOpcells?.reduce((acc, opcell) => {
        return (
          parseFloat(acc) +
          parseFloat(opcell?.price.replace("R$ ", "").replace(",", "."))
        );
      }, 0)
    );
    installments_price += parseFloat(
      selectedOpcells?.reduce((acc, opcell) => {
        return (
          parseFloat(acc) +
          parseFloat(
            opcell?.installments_price.replace("R$ ", "").replace(",", ".")
          ) *
            parseFloat(opcell?.installments_quantity)
        );
      }, 0)
    );

    let options = [];
    if (method == 1) {
      let variations = course.variations.sort((a,b) => a.installment - b.installment).filter((item)=>{
        if(item.is_student==is_student){
          return true;
        }
        return false;
      });
      if(variations.length==0){
        options.push({
          quantity: 1,
          value: price,
          label: "À vista por " + maskPrice(parseFloat(price).toFixed(2)),
        });
        if (installments_price >= 100) {
          for (let i = 2; i <= course.installments_quantity; i++) {
            options.push({
              quantity: i,
              value: parseFloat(parseFloat(installments_price / i).toFixed(2)),
              label:
                i +
                "x de " +
                maskPrice(parseFloat(installments_price / i).toFixed(2)),
            });
          }
        }
      }
      else{
        variations.map((item)=>{
          options.push({
            quantity: item.installment,
            value: parseFloat(item.price).toFixed(2),
            label: item.installment==1? "À vista por " + maskPrice(parseFloat(item.price).toFixed(2)):(
              item.installment +
                "x de " +
                maskPrice(parseFloat(item.price).toFixed(2))
            ),
          });
        })
      }
    } else if (method == 2) {
      let variations = course.variations.sort((a,b) => a.installment - b.installment).filter((item)=>{
        if(item.is_student==is_student){
          return true;
        }
        return false;
      });
      if(variations.length==0){

        options.push({
          quantity: 1,
          value: price / 2,
          label:
            "À vista por " +
            maskPrice(parseFloat(price / 2).toFixed(2)) +
            " por cartão",
        });
        if (installments_price / 2 >= 100) {
          installments_price = parseFloat(
            parseFloat(installments_price / 2).toFixed(2)
          );
          for (let i = 2; i <= course.installments_quantity; i++) {
            options.push({
              quantity: i,
              value: parseFloat(parseFloat(installments_price / i).toFixed(2)),
              label:
                i +
                "x de " +
                maskPrice(parseFloat(installments_price / i).toFixed(2)) +
                " por cartão",
            });
          }
        }
      }
      else{
        variations.map((item)=>{
          options.push({
            quantity: item.installment,
            value: parseFloat(item.price/2).toFixed(2),
            label: item.installment==1? "À vista por " + maskPrice(parseFloat(item.price/2).toFixed(2)):(
              item.installment +
                "x de " +
                maskPrice(parseFloat(item.price/2).toFixed(2))
            ),
          });
        })
      }
    }
    return options;
  };

  const getPaymentMethod = () => {
    return MethodsMapping.getComponent(method);
  };
  console.log("address_id", address_id, paymentPix);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: course?.checkout_bg_color,
        position: "relative",
      }}
    >
      {SetPopupRender()}

      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100vh",
          zIndex: 100,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",

          gap: "2rem",
          flexDirection: "column",
          opacity: paymentSuccess || paymentPix ? 1 : 0,
          transition: "all 600ms ease",
          top: paymentSuccess || paymentPix ? "0" : "-100vh",
        }}
      >
        {paymentSuccess && expired == false && (
          <Typography
            variant="h3"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Pagamento realizado com sucesso!
          </Typography>
        )}
        {expired && (
          <Typography
            variant="h3"
            style={{ color: "black", fontWeight: "bold" }}
          >
            QRCode Expirado
          </Typography>
        )}
        {paymentPix && expired == false && (
          <Typography
            variant="h3"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Faça o pix para finalizar o pagamento
          </Typography>
        )}
        {paymentPix && expired == false && pixData.qr_code_url && (
          <div>
            <img
              style={{ height: 225, width: 225 }}
              src={pixData.qr_code_url}
            />
            <Counter
              handleClose={() => {
              clearTimeout(timeout_order.current);

                setExpired(true);


              }}
              timer={handleTimeoutPayment(pixData.expires_at).seconds}
            />
          </div>
        )}
        {paymentSuccess && expired == false && (
          <div className="check-container">
            <div className="check-background">
              <svg
                viewBox="0 0 65 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 25L27.3077 44L58.5 7"
                  stroke="white"
                  stroke-width="13"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="check-shadow"></div>
          </div>
        )}
        <Typography
          variant="p"
          style={{ color: "black", fontWeight: "bold", fontSize: "1.2rem" }}
        >
          {paymentSuccess
            ? 'Vá para a seção "Meus Cursos" para ver o(s) curso(s) adquirido(s)!'
            : expired
            ? "Faça o pedido novamente"
            : "Ao realizar o pix, o(s) curso(s) já estará(am) disponível(is) para você!"}
        </Typography>
        {expired && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setExpired(false);
              setPaymentPix(false);
              setLoadingOrder(false);
            }}
          >
            Voltar para o carrinho
          </Button>
        )}
        {paymentSuccess && expired == false && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/my-courses`);
            }}
          >
            Ir para Meus Cursos
          </Button>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          // width: "100vw",
          // height: "100vh",
          zIndex: 1,
          overflow: "hidden",
        }}
      >
        <img
          src={STORAGE_URL + course?.checkout_cover_image}
          style={{
            objectFit: "cover",
            backgroundPosition: "center",
            width: "100vw",
            height: "100vh",
            maskImage:
              " linear-gradient(to bottom, rgba(0, 0, 0, 1) 10%, transparent 76%)",
          }}
        />
      </div>

      <Header pages={is_logged ? loggedPages : pages} />

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          minHeight: "100vh",
          justifyContent: "space-between",
          padding: "0!important",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          "& .MuiFormControl-root": {
            borderRadius: "0.4rem",
            // overflow: "hidden",
          },
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md:
                course?.checkout_aside_banner !== null &&
                course?.checkout_aside_banner !== undefined
                  ? "62%"
                  : "100%",
            },
            display: "flex",
            flexDirection: "column",
            backgroundColor: course?.checkout_card_color,
          }}
        >
          {course?.checkout_banner !== null &&
            course?.checkout_banner !== undefined && (
              <img
                src={STORAGE_URL + course?.checkout_banner}
                alt="Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  aspectRatio: "22/9",
                }}
              />
            )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: {
                xs: "0.6rem",
                sm: "1rem",
                md: "2rem",
              },
              gap: "1rem",
            }}
          >
            <CheckoutHeader
              icon={<AccountCircleIcon sx={{ fontSize: "2.2rem" }} />}
              title="Logado como:"
              color={course?.checkout_accent_color}
            />
            <UserInfo
              {...{
                course,
                user,
              }}
            />

            <CheckoutHeader
              icon={<Phone sx={{ fontSize: "2.2rem" }} />}
              title="Dados Gerais:"
              color={course?.checkout_accent_color}
            />
            <CustomerInfo
              {...{
                user,
                course,
                cpf,
                setCpf,
                user_name,
                setUserName,
                gender,
                setGender,
                birth_date,
                setBirthDate,
                phone,
                setPhone,
              }}
            />

            <CheckoutHeader
              icon={<Home sx={{ fontSize: "2.2rem" }} />}
              title="Endereço:"
              color={course?.checkout_accent_color}
            />
            <AddressInfo
              {...{
                course,
                user_id: user?.id,
                adress: adress,
                SetAdress: SetAdress,
                SetAddressId,
                has_adress,
                SetHasAdress,
                form_edited,
                SetFormEdited,
                SetPopup,
                submitAdress,
              }}
            />

            <CheckoutHeader
              icon={<PaymentIcon sx={{ fontSize: "2.2rem" }} />}
              title="Pagamento:"
              color={course?.checkout_accent_color}
            />

            {address_id != null && address_id && (
              <Methods payments_types={course.payments_types} color={course?.checkout_font_color} {...{ method, setMethod }} />
            )}
            {(address_id == null || !address_id) && (
              <p style={{ fontSize: 18, color: course?.checkout_font_color }}>
                Preencha os campos anteriores para adicionar uma forma de
                pagamento
              </p>
            )}

            {method === MethodsMapping.credit_card && (
              <UserCards
              color={course?.checkout_font_color}
                cardId={cardId}
                setCardId={setCardId}
                billingAddress={{
                  street: adress["street"].value,
                  number: adress["number"].value,
                  complement: adress["complement"].value,
                  neighborhood: adress["neighborhood"].value,
                  city: adress["city"].value,
                  state: adress["state"].value,
                  zip_code: adress["zipCode"].value,
                }}
              />
            )}

            {method === MethodsMapping.multi_payment && (
              <MultiCards
              color={course?.checkout_font_color}

                multi_cards={multi_cards}
                setCardId={handleMultiPaymentCards}
                billingAddress={{
                  street: adress["street"].value,
                  number: adress["number"].value,
                  complement: adress["complement"].value,
                  neighborhood: adress["neighborhood"].value,
                  city: adress["city"].value,
                  state: adress["state"].value,
                  zip_code: adress["zipCode"].value,
                }}
              />
            )}

            {(method === MethodsMapping.multi_payment ||
              method === MethodsMapping.credit_card) && (
              <div>
                <Typography variant={"h5"} style={{color:course?.checkout_font_color}} sx={{ mb: 1, mt: 2 }}>
                  <b>Número de Parcelas:</b>
                </Typography>

                <TextField
                  select
                  style={{ marginTop: 10,background:'white' }}
                  label="Pacelas"
                  fullWidth
                  onChange={({ target }) => setInstallment(target.value)}
                  value={installments}
                >
                  {installmentsOptions().map((item, id) => (
                    <MenuItem key={id} value={item.quantity}>
                      {item.label}
                    </MenuItem>
                  ))}

                  {/* {fillMonth.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))} */}
                </TextField>
              </div>
            )}

            <CheckoutHeader
              icon={<ShoppingCartIcon sx={{ fontSize: "2.2rem" }} />}
              title="Confirmação:"
              color={course?.checkout_accent_color}
            />
            <ConfirmOrder
              {...{
                course,
                opcells,
                is_student,
                selectedOpcells,
                setSelectedOpcells,
                discount_price,
                discount_type,
                used_coupon,
                setUsedCoupon,
                total_price,
                setTotalPrice,
                installments,
                validateCoupon,
              }}
            />

            {loading_order == false && (
              <Button
                variant="contained"
                size="large"
                onClick={buyCourse}
                endIcon={<CheckIcon />}
                sx={{
                  backgroundColor: course?.checkout_accent_color,
                  marginBottom: "4rem",
                }}
              >
                Confirmar Pedido
              </Button>
            )}
            {loading_order == true && (
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </Box>

          {course?.checkout_banner !== null &&
            course?.checkout_banner !== undefined && (
              <img
                src={STORAGE_URL + course?.checkout_banner}
                alt="Banner"
                style={{
                  width: "100%",
                  objectFit: "cover",
                  aspectRatio: "22/9",
                }}
              />
            )}
        </Box>
        {course?.checkout_aside_banner !== null &&
          course?.checkout_aside_banner !== undefined && (
            <Box
              sx={{
                width: "38%",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
                flexDirection: "column",
                // backgroundColor: "blue",
                // position: "relative",
              }}
            >
              {course?.checkout_aside_banner !== null &&
                course?.checkout_aside_banner !== undefined && (
                  <img
                    src={STORAGE_URL + course?.checkout_aside_banner}
                    alt="Banner"
                    style={{
                      objectFit: "cover",
                      aspectRatio: "9/22",
                      width: "100%",
                      position: "-webkit-sticky",
                      position: "sticky",
                      top: "4rem",
                      borderRadius: 0,
                    }}
                  />
                )}
            </Box>
          )}
      </Container>
    </div>
  );
};

export default CheckoutPage;
