/**
 *
 * Component that shows a welcoming text, a banner and a video
 * It's called in the landing page
 *
 */
import { Box, Container, Typography, useTheme } from "@mui/material";
import Video from "components/Video";
import { Get } from "utils/request";
import { STORAGE_URL } from "variables";
import { useHistory } from "react-router";
import ReactPlayer from "react-player";
import AsideBanner from "./AsideBanner";
import { useQuery } from "react-query";

const Welcome = ({description}) => {
  const history = useHistory();
  const theme = useTheme();

  const { data: advideoData } = useQuery(
    "landingAdvideo",
    async () => await Get("site-rt/advertising-video")
  );
  const { video, link } = advideoData?.advideo || {};

  const { data: asideBannerData } = useQuery(
    "landingAsideBanner",
    async () => await Get("site-rt/aside-banner")
  );
  const { asideBanner } = asideBannerData || {};

  return (
    <div style={{display: 'flex', overflow: "hidden", position: 'relative', justifyContent: 'center'}}>
      <Container
        maxWidth="xl"
        sx={{
          height: "max-content",
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          zIndex: 10,
          gap: '4rem',
          flexDirection: { xs: "column", md: "row" },
          margin: { xs: "8vh 0", md: "12vh 0" },
          paddingBottom: '5rem'
        }}
      >
        <Box sx={{ maxWidth: { xs: "100%", md: "50%" } }}>
          <div
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                marginBottom: "1rem",
                gap: "0.6rem", 
              }}
            >
              <Typography
                variant="h3"
                style={{
                  marginBottom: "1.6rem",
                  textAlign: "center",
                }}
                dangerouslySetInnerHTML={{ __html: description?.title }}
              />
              <Typography
                variant="body1"
                sx={{ textAlign: "justify" }}
                dangerouslySetInnerHTML={{ __html: description?.description }}
              />
              <Typography
                variant="h6"
                dangerouslySetInnerHTML={{ __html: description?.sub }}
              />
            </div>
          </div>          
        </Box>

        {/* <AsideBanner /> */}
        <div>
            {video && video != "undefined" && link === null ? (
              <Video
                src={video != "undefined" ? STORAGE_URL + video : null}
                containerProps={{
                  style: {
                    borderRadius: "1rem",
                    overflow: "hidden",
                  },
                }}
              />
            ) : (
              <ReactPlayer url={link} controls width="min(640px, 80vw)" />
            )}
          </div>
      </Container>
      <div
        style={{
          position: "absolute",
          background: "rgb(240, 244, 244)",
          width: "120vw",
          height: "80rem",
          top: '-40rem',
          // transform: "rotate(4deg) scale(1.2)",
          borderRadius: '50%',
          opacity: 0.8,
          zIndex: 1,
        }}
      >
      </div>
    </div>
  );
};

export default Welcome;
