import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Menu,
  Drawer,
  List,
  ListItem,
  Grid,
  IconButton,
  useMediaQuery,
  ButtonBase,
  useTheme,
} from "@mui/material";
import SearchInput from "components/Inputs/SearchInput";
import ProfileMenu from "ProfileMenu";
import MenuIcon from "@mui/icons-material/Menu";
import GroupIcon from "@mui/icons-material/Group";
import { useEffect, useState } from "react";
import TopNavigation from "./TopNavigation";

import styles from "./index.module.css";
import logo from "assets/images/rt/white_logo.png";
import LeftNavigation from "./LeftNavigation";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { STORAGE_URL } from "variables";

export const Header = (props) => {
  const { pages } = props;

  const [expanded, SetExpanded] = useState(false);
  const [activeItems, SetActiveItems] = useState([]);
  const [mui_theme, setMuiTheme] = useState({});

  const matchesXs = useMediaQuery("(max-width: 960px)");
  const history = useHistory();
  const user = useSelector((store) => store.AppReducer.user);
  const theme = useTheme();

  const HandleDropdownToggle = () => {
    SetExpanded(!expanded);
    if (activeItems.length != 0) SetActiveItems([]);
  };

  const HandleDropdownClose = () => {
    SetExpanded(false);
    SetActiveItems([]);
  };

  const HandleItemClick = (page) => {
    //if the page has a link, send the user to it, instead of opening the menu
    if (page?.link) {
      history.push(page?.link);
      return;
    }
    const items = page?.items || [];
    HandleDropdownToggle();
    SetActiveItems([...items]);
  };

  const headerTheme = useSelector(
    (store) => store.StylesReducer.pagesThemes?.header
  );

  useEffect(() => {
    setMuiTheme(headerTheme?.palette?.data);
    console.log("header theme", headerTheme?.palette?.data);
  }, [headerTheme]);

  return (
    <Box
      style={{
        display: "flex",
      }}
    >
      <AppBar
        position="fixed"
        style={{
          zIndex: 1500,
          background: `linear-gradient(90deg, ${
            mui_theme?.background_color_1
          } 0%, ${
            mui_theme?.background_color_2
              ? mui_theme?.background_color_2
              : mui_theme?.background_color_1
          } 100%)`,
          color: `${mui_theme?.font_color}`,
        }}
      >
        <Toolbar>
          <Box
            display={{ xs: "block", md: "none" }}
            style={{
              marginRight: 5,
            }}
          >
            <IconButton onClick={HandleDropdownToggle}>
              <MenuIcon htmlColor={`${mui_theme?.font_color}`} />
            </IconButton>
          </Box>
          {/* <Box
            style={{
              backgroundPosition: "center",
              // backgroundSize: "cover",
              backgroundImage: `url(${logo})`,
              backgroundRepeat: "no-repeat",
              width: "10rem",
              height: "4em",
            }}
            onClick={() => history.push("/")}
          /> */}
          <a href="/">
            <img
              style={{ height: "3.5rem" }}
              src={
                mui_theme?.header_logo
                  ? STORAGE_URL + mui_theme?.header_logo
                  : logo
              }
              alt="RT logotipo"
            />
          </a>
          <Box
            display={{ xs: "none", md: "flex" }}
            // margin={"0 1em"}
            style={{
              flexGrow: 1,
              justifyContent: "center",
              width: "100%",
            }}
          >
            {pages?.map((page, pageKey) => (
              <Button
                sx={{
                  color: `${mui_theme?.font_color}`,
                  display: "block",
                  fontSize: "1rem",
                  "&:hover": {
                    color: mui_theme?.font_color,
                    background: "rgba(0,0,0,0.1)",
                    opacity: 0.8,
                  },
                }}
                onClick={() => HandleItemClick(page)}
                key={pageKey}
              >
                <b>{page?.label}</b>
              </Button>
            ))}
          </Box>
          <div className={styles.rightContainer}>
            {/* {!matchesXs && (
              <Box sx={{opacity: '0.6'}} marginRight="0.5em" className="flex align-center jcc">
                <SearchInput placeholder="Buscar..." />
              </Box>
            )} */}
            {/* <Button
              sx={{
                color: `${mui_theme?.font_color}`,
                display: "block",
                whiteSpace: "nowrap",
                fontSize: "1rem",
                "&:hover": {
                  color: mui_theme?.font_color,
                  background: "rgba(0,0,0,0.1)",
                  opacity: 0.8,
                },
                fontWeight: "bold",
              }}
            >
              <a
                href="/clube-rt"
                target="_self"
                style={{
                  color: `${mui_theme?.font_color}`,
                  textDecoration: "none",
                }}
              >
                Clube RT
              </a>
            </Button> */}
            <Box>
              <ProfileMenu {...{ font_color: mui_theme?.font_color }} />
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      {matchesXs ? (
        <LeftNavigation
          open={expanded}
          pages={pages}
          onClose={HandleDropdownClose}
          disableScrollLock
        />
      ) : (
        <TopNavigation
          open={activeItems?.length != 0}
          items={activeItems}
          onClose={HandleDropdownClose}
          disableScrollLock
        />
      )}
      <Toolbar />
    </Box>
  );
};
