import { Box, Container, Typography } from "@mui/material";
import Video from "components/Video";
import { useEffect, useState } from "react";
import { fileToBase64 } from "utils/images/fileToBase64";
import { STORAGE_URL } from "variables";
import Form from "../../Form";

const HeaderSection = ({ course, style }) => {
  const {
    name,

    subtitle,
    description,

    cover_image = "",
    video_trailer = null,
    section1_bgColor_1,
    section1_bgColor_2,
    section1_accentColor,
    image = "",

    price,

    inDateRange,
  } = course;

  const [imageSrc, setImageSrc] = useState("");
  const [coverImageSrc, setCoverImageSrc] = useState("");

  const [videoBase64, setVideoBase64] = useState(null);

  const renderVideo = async () => {
    if (video_trailer && video_trailer[0]) {
      await fileToBase64(video_trailer[0]).then((res) => {
        setVideoBase64(res);
      });
    }
  };

  useEffect(() => {
    renderVideo();
  }, [video_trailer]);

  useEffect(() => {
    if (image === "" || image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(image);
    } catch (error) {
      setImageSrc(`${STORAGE_URL}${image}`);
    }
  }, [image]);

  useEffect(() => {
    if (cover_image === undefined || cover_image === null) return;
    if (cover_image === "" || cover_image.length === 0) return;

    let reader = new FileReader();
    reader.onload = function (e) {
      setCoverImageSrc(e.target.result);
    };
    try {
      reader.readAsDataURL(cover_image);
    } catch (error) {
      setCoverImageSrc(`${STORAGE_URL}${cover_image}`);
    }
  }, [cover_image]);

  return (
    <section
      style={{
        minHeight: "100vh",
        display: "flex",
        // flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        background: `linear-gradient(180deg, ${section1_bgColor_1} 0%, ${section1_bgColor_2} 100%)`,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          // justifyContent: "center",
          // alignItems: "center",
          display: {
            xs: "none",
            md: "none",
            lg: "flex",
          },
          flexDirection: "row",
          zIndex: 10,
          position: "relative",
          gap: "3rem",
        }}
      >
        <Box
          sx={{
            width: "50%",
            padding: "0.2rem",
            borderRadius: "0.89rem",
          }}
        >
          {course?.video_trailer != null && (
            <Video
              containerProps={{
                style: {
                  borderRadius: "0.8rem",
                  width: "100%",
                },
              }}
              src={
                course?.video_trailer
                  ? STORAGE_URL + course?.video_trailer
                  : null
              }
            />
          )}
          {course?.video_trailer == null && (
            <img
              style={{
                borderRadius: "0.8rem",
                width: "100%",
              }}
              src={STORAGE_URL + course?.image}
            />
          )}
        </Box>

        <div
          style={{
            width: "50%",
            // justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
            gap: "1.4rem",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              // justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1.4rem",
            }}
          >
            {/* <Typography
              sx={{
                backdropFilter: "blur(1.4rem)",
                padding: "0.1rem 1rem",
                borderRadius: "0.8rem",
                color: section1_accentColor,
              }}
              dangerouslySetInnerHTML={{ __html: name }}
            /> */}

            <Typography
              sx={{
                backdropFilter: "blur(1.4rem)",
                padding: "0.1rem 1rem",
                borderRadius: "0.8rem",
              }}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />

            {/* <Typography
              sx={{
                backdropFilter: "blur(1.4rem)",
                padding: "0.1rem 1rem",
              }}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            /> */}
          </div>

          <div>
            <Form
              image={image}
              price={price}
              inDateRange={inDateRange}
              color={section1_accentColor}
              {...course}
            />
          </div>
        </div>
      </Container>

      <Container
       maxWidth="xl"
        sx={{
          minHeight: "100vh",
          display: {
            xs: "flex",
            md: "flex",
            lg: "none",
          },
          flexDirection: "column",
          // justifyContent: "center",
          position: "relative",
          background: `linear-gradient(180deg, ${section1_bgColor_1} 0%, ${section1_bgColor_2} 100%)`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "2rem 0",
            position: "relative",
            zIndex: 10,
          }}
        >
          {/* <Typography
              sx={{
                backdropFilter: "blur(1.4rem)",
                padding: "0.1rem 1rem",
                borderRadius: "0.8rem",
                color: section1_accentColor,
              }}
              dangerouslySetInnerHTML={{ __html: name }}
            /> */}
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              backdropFilter: "blur(1.4rem)",
              padding: "0.1rem 1rem",
              borderRadius: "0.8rem",
            }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />

          <Box
            sx={{
              // maxHeight: "60vh",
              marginTop: "2rem",
              maxWidth: "80%",
              width: "100%",
              width: "max-content",
              overflow: "hidden",
            }}
          >
            {videoBase64 !== null &&
            videoBase64 !== undefined &&
            videoBase64 !== "" ? (
              <Video
                containerProps={{
                  style: {
                    borderRadius: "0.8rem",
                    height: "100%",
                  },
                }}
                src={videoBase64}
              />
            ) : (
              <img
                style={{
                  borderRadius: "0.8rem",
                  height: "100%",
                  width:'-webkit-fill-available',
                  margin: "0 auto",
                }}
                src={imageSrc}
              />
            )}
          </Box>

          <div style={{ marginTop: "4rem" }}>
            <Form
              image={image}
              price={price}
              inDateRange={inDateRange}
              color={section1_accentColor}
              {...course}
            />
          </div>
        </div>
      </Container>

      {/* ghost image cover */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
          opacity: "0.5",
          top: "0",
          overflow: "hidden",
        }}
      >
        <img className={style.ghostImage} src={coverImageSrc} />
      </div>
    </section>
  );
};

export default HeaderSection;
