import { Box } from "@mui/material";
import { logout } from "actions/AppActions";
import { Show } from "actions/SnackbarActions";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import pfp_placeholder from "assets/images/default.png";
import { STORAGE_URL } from "variables";

const UserInfo = ({ course, user }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const Logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    dispatch(logout());

    dispatch(
      Show({
        show: true,
        severity: "success",
        message: "Deslogado com sucesso! Faça login novamente.",
      })
    );

    setTimeout(() => {
      history.push("/login");
    }, 500);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        gap: "2vw",
        alignItems: "center",
      }}
    >
      <Box
        component={"img"}
        src={
          user?.profile_image
            ? STORAGE_URL + user?.profile_image
            : pfp_placeholder
        }
        alt="Usuário"
        sx={{
          objectFit: "cover",
          height: {
            xs: "5rem",
            sm: "7rem",
          },
          aspectRatio: "1/1",
          display: "inline-block",
          borderRadius: "50%",
          backgroundColor: "white",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          color: course?.checkout_font_color,
        }}
      >
        <h4
          style={{
            color: course?.checkout_font_color,
          }}
        >
          {user?.name}
        </h4>
        <p>{user?.email}</p>
        <a
          href="#"
          onClick={Logout}
          style={{
            color: course?.checkout_accent_color,
          }}
        >
          Não é você? Sair
        </a>
      </div>
    </div>
  );
};

export default UserInfo;
